export default {
  name: "ListKolfund",
  data() {
    return {
      isShow: false,
      form: {
        checkingSavingAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      session: {
        checkingSavingAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      property: {
        listElement: {
          collectorFunding: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          savingAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          collectorFundingUserId: "",
          chartOfAccountCodePair: "",
          accountNumberPair: "",
          officeId: "",
          statusId: "",
          dateFrom: "",
          dateTo: "",
        },
        animation: {
          isDownloadingFile: false,
          CheckingSavingAccount: {
            isLoading: false,
          },
        },
        modal: {
          showModalCheckingSavingAccount: false,
          showModalResultListSavingAccount: false,
        },
      },
      table: {
        header: {},
        data: {
          collectorFunding: [],
          savingAccount: [],
        },
      },
      options: {
        coa: [],
        userId: [],
        office: [],
        statusAccount: [],
      },
    };
  },
  methods: {
    // START MODAL SEARCH REKENING
    clearModalCheckingSavingAccount() {
      this.form.checkingSavingAccount.accountNumber = "";
    },
    async checkingAvailabilitySavingAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
        this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
        this.form.checkingSavingAccount.accountNumber = "";
        this.form.checkingSavingAccount.cifIdName = "";

        try {
          this.property.animation.CheckingSavingAccount.isLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account/cross-office/simple-list",
            payload: {
              accountNumber: this.session.checkingSavingAccount.accountNumber,
              idCardNumber: "",
              cifIdName: this.session.checkingSavingAccount.cifIdName,
              page: 0,
              size: this.property.listElement.savingAccount.perPage,
            },
          });
          await this.simpleWait(500);
          if (resp.data.code === "SUCCESS") {
            this.table.data.savingAccount = resp.data.data.content;
            this.property.modal.showModalCheckingSavingAccount = false;
            this.property.modal.showModalResultListSavingAccount = true;
            this.property.listElement.savingAccount.rows =
              resp.data.data.totalElements;
          } else if (resp.data.code === "DATA NOT FOUND") {
            this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
            this.form.checkingSavingAccount.accountNumber = "";
            this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
            this.form.checkingSavingAccount.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `Nomor Rekening Tidak Tersedia!`,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
            this.form.checkingSavingAccount.accountNumber = "";
            this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
            this.form.checkingSavingAccount.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          await this.simpleWait(500);
          this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
          this.form.checkingSavingAccount.accountNumber = "";
          this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
          this.form.checkingSavingAccount.cifIdName = "";
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(500);
          this.property.animation.CheckingSavingAccount.isLoading = false;
        }
      }
    },
    closeModalCheckingSavingAccount() {
      this.property.modal.showModalCheckingSavingAccount = false;
      this.clearModalCheckingSavingAccount();
    },
    clearModalResultCheckingSavingAccount() {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.perPage = 5;
    },
    async changePaginationSavingAccount(event) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/cross-office/simple-list",
          payload: {
            accountNumber: this.session.checkingSavingAccount.accountNumber,
            idCardNumber: "",
            cifIdName: this.session.checkingSavingAccount.cifIdName,
            page: event - 1,
            size: this.property.listElement.savingAccount.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccount = resp.data.data.content;
          this.property.listElement.savingAccount.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetSavingAccount(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetSavingAccount(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccount.downloading = false;
      }
    },
    failedGetSavingAccount(resp) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.message = resp.data.message;
    },
    handleErrorGetSavingAccount(error) {
      console.log(error.response);
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    // END MODAL SEARCH REKENING
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        collectorFundingUserId: this.property.filterDto.collectorFundingUserId,
        chartOfAccountCodePair: this.property.filterDto.chartOfAccountCodePair,
        accountNumberPair: this.property.filterDto.accountNumberPair,
        officeId: this.property.filterDto.officeId,
        statusId: this.property.filterDto.statusId,
        dateFrom: this.property.filterDto.dateFrom,
        dateTo: this.property.filterDto.dateTo,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "collector-funding/detail/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Kolector_Funding_Detail.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    failedGetCollectorFunding(resp) {
      this.property.listElement.collectorFunding.rows = 0;
      this.table.data.collectorFunding = [];
      this.property.listElement.collectorFunding.message = resp.data.message;
    },
    handleErrorGetCollectorFunding(error) {
      console.log(error.response);
      this.table.data.collectorFunding = [];
      this.property.listElement.collectorFunding.rows = 0;
      this.property.listElement.collectorFunding.currentPage = 1;
      this.property.listElement.collectorFunding.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getCollectorFunding() {
      this.table.data.collectorFunding = [];
      this.property.listElement.collectorFunding.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "collector-funding-detail/simple-list",
          payload: {
            collectorFundingUserId: "",
            chartOfAccountCodePair: "",
            accountNumberPair: "",
            officeId: "",
            statusId: "",
            dateFrom: "",
            dateTo: "",
            page: 0,
            size: 10,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.collectorFunding = resp.data.data.content;
            this.property.listElement.collectorFunding.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetCollectorFunding(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCollectorFunding(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.collectorFunding.downloading = false;
        }, timeout);
      }
    },
    async searchCollectorFunding() {
      this.table.data.collectorFunding = [];
      this.property.listElement.collectorFunding.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "collector-funding-detail/simple-list",
          payload: {
            collectorFundingUserId: this.property.filterDto
              .collectorFundingUserId,
            chartOfAccountCodePair: this.property.filterDto
              .chartOfAccountCodePair,
            accountNumberPair: this.property.filterDto.accountNumberPair,
            officeId: this.property.filterDto.officeId,
            statusId: this.property.filterDto.statusId,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: 0,
            size: this.property.listElement.collectorFunding.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.collectorFunding = resp.data.data.content;
            this.property.listElement.collectorFunding.rows =
              resp.data.data.totalElements;
            this.property.listElement.collectorFunding.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetCollectorFunding(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCollectorFunding(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.collectorFunding.downloading = false;
        }, timeout);
      }
    },
    async changePaginationCollectorFunding(event) {
      this.table.data.collectorFunding = [];
      this.property.listElement.collectorFunding.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "collector-funding-detail/simple-list",
          payload: {
            collectorFundingUserId: this.property.filterDto
              .collectorFundingUserId,
            chartOfAccountCodePair: this.property.filterDto
              .chartOfAccountCodePair,
            accountNumberPair: this.property.filterDto.accountNumberPair,
            officeId: this.property.filterDto.officeId,
            statusId: this.property.filterDto.statusId,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: event - 1,
            size: this.property.listElement.collectorFunding.perPage,
          },
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.collectorFunding = resp.data.data.content;
            this.property.listElement.collectorFunding.rows =
              resp.data.data.totalElements;
            this.property.listElement.collectorFunding.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetCollectorFunding(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetCollectorFunding(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.collectorFunding.downloading = false;
        }, timeout);
      }
    },
    async authorizationCollectorFunding(props) {
      console.log(props);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "collector-funding-detail/authorized/" +
                  props.row.collectorFundingDetailId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.collectorFunding.currentPage = 1;
                  this.property.filterDto.userName = "";
                  this.property.filterDto.chartOfAccountCode = "";
                  this.property.filterDto.officeId = "";
                  this.property.listElement.collectorFunding.rows = 0;
                  this.getCollectorFunding();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    routerToPageAddCollectorFundingManual(props) {
      this.$router.push(
        `/collector-funding-detail/add/manual?refId=${this.encryptBASE64(
          JSON.stringify({
            savingAccountId: props.row.savingAccountId,
          })
        )}`
      );
    },
    routerToPageAddCollectorFundingSetor() {
      this.$router.push("add/setor");
    },
    routeToPageRincianCollectorFunding(props) {
      sessionStorage.setItem(
        "COLLECTOR_FUNDING_DETAIL_ID_EDIT",
        props.row.collectorFundingDetailId
      );
      this.$router.push("edit");
    },
    async getReferenceUserId() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "collector-funding/simple-list",
          payload: {
            userName: "",
            chartOfAccountCode: "",
            officeId: "",
            page: 0,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.userId = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((index) => {
            const text = index.userName;
            const value = index.userId;
            this.options.userId.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "chart-of-account",
            params: {
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.coa = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((index) => {
            const text = `${index.chartOfAccountCode} - ${index.description}`;
            const value = index.chartOfAccountCode;
            const description = index.description;
            this.options.coa.push({
              text,
              value,
              description,
            });
          });
        }
      } catch (error) {}
    },
    async deleteCollectorFunding(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl:
                  "collector-funding-detail/" +
                  props.row.collectorFundingDetailId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                this.property.listElement.collectorFunding.currentPage = 1;
                this.property.filterDto.userName = "";
                this.property.filterDto.chartOfAccountCode = "";
                this.property.filterDto.officeId = "";
                this.property.listElement.collectorFunding.rows = 0;
                this.getCollectorFunding();
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Semua Cabang --" }];
          resp.data.data.content.map((data) => {
            var value = data.officeId;
            var text = data.officeName;
            this.options.office.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
    setDefaultDate() {
      this.property.filterDto.dateTo = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
  },
  mounted() {
    this.getCollectorFunding();
    this.getReferenceChartOfAccount();
    this.getReferenceUserId();
    this.getReferenceOffice();
    this.getReferenceStatusAccount();
    this.setDefaultDate();
  },
};
